export default (eventType, eventProperties, gtm_special_event) => {
  if (process.env.NODE_ENV !== "development") {
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: gtm_special_event || "logEvent",
        eventType,
        eventProperties,
      })
    }
  } else {
    console.log({
      event: gtm_special_event || "logEvent",
      eventType,
      eventProperties,
    })
  }
}
