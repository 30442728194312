import React from 'react'

export default () => (
  <div style={{display: 'none'}}>
    <svg>
      <symbol id="ShareIcon" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 12.0001H5.125C3.39911 12.0001 2 13.3992 2 15.1251V30.7501C2 32.476 3.39911 33.8751 5.125 33.8751H23.875C25.6009 33.8751 27 32.476 27 30.7501V15.1251C27 13.3992 25.6009 12.0001 23.875 12.0001H19.1875" stroke="#0B7DF2" strokeWidth="2.5"/>
        <path d="M14.5 21.6875V2M14.5 2L19.5 6.6875M14.5 2L9.5 6.6875" stroke="#3a82f7" strokeWidth="2.5"/>
      </symbol>
      <symbol id="StarIcon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4165 5.51315L10.183 5.49939L8.53283 0.414515C8.45047 0.160584 8.23502 -0.0101626 7.97481 0.000469969C7.71878 0.0035972 7.50273 0.169966 7.42574 0.425773L5.88058 5.49939L0.583841 5.51315C0.327807 5.51315 0.110566 5.67827 0.0305926 5.93282C-0.0499776 6.18801 0.0317862 6.45632 0.238285 6.61519L4.38317 9.81809L2.75744 15.2013C2.67986 15.4571 2.76341 15.7236 2.97111 15.8812C3.07555 15.96 3.19491 16 3.31427 16C3.43244 16 3.55002 15.9612 3.65207 15.8843L8.02196 12.657L12.3483 15.8818C12.5542 16.0394 12.8239 16.0388 13.031 15.8812C13.2387 15.7242 13.3223 15.4577 13.2453 15.2019L11.6172 9.81809L15.7615 6.61581C15.968 6.45632 16.0497 6.18801 15.9698 5.93345C15.8898 5.67827 15.6725 5.51315 15.4165 5.51315Z" />
      </symbol>
      <symbol id="SmartphoneIcon" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1V15H11V1H1ZM1 0H11C11.5523 0 12 0.447715 12 1V15C12 15.5523 11.5523 16 11 16H1C0.447715 16 0 15.5523 0 15V1C0 0.447715 0.447715 0 1 0ZM6 14C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14Z" fill="#8E8E93"/>
      </symbol>
      <symbol id="DownIcon" viewBox="0 0 14 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0716 12.5651L13.5 13.9908L7.00564 20.4975L0.5 13.9915L1.92711 12.5645L7.00433 17.642L12.0716 12.5651Z" fill="#C7C7CC"/>
      </symbol>
      <symbol id="RightIcon" viewBox="0 0 12 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.06665 9.87054L4.45974 8.5L11.0667 15L4.45974 21.5L3.06665 20.1295L8.28048 15L3.06665 9.87054Z" fill="#C7C7CC"/>
      </symbol>
      <symbol id="LogoApple" width="17" height="20" viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.4933 15.5861C16.1909 16.2848 15.8329 16.928 15.4181 17.5193C14.8526 18.3255 14.3897 18.8835 14.0329 19.1934C13.4798 19.702 12.8873 19.9625 12.2527 19.9773C11.7972 19.9773 11.2478 19.8477 10.6083 19.5847C9.96673 19.323 9.37712 19.1934 8.838 19.1934C8.27259 19.1934 7.66619 19.323 7.01757 19.5847C6.36795 19.8477 5.84464 19.9847 5.44453 19.9983C4.83603 20.0242 4.2295 19.7563 3.62409 19.1934C3.23768 18.8563 2.75436 18.2786 2.17537 17.4601C1.55415 16.586 1.04343 15.5725 0.643314 14.417C0.214808 13.1689 0 11.9603 0 10.7902C0 9.44984 0.289621 8.29383 0.869727 7.32509C1.32564 6.54696 1.93216 5.93316 2.69128 5.48255C3.45039 5.03195 4.27061 4.80233 5.15392 4.78763C5.63724 4.78763 6.27104 4.93714 7.05867 5.23096C7.84408 5.52576 8.34839 5.67526 8.56949 5.67526C8.7348 5.67526 9.29503 5.50045 10.2448 5.15195C11.1429 4.82874 11.9009 4.69492 12.5218 4.74764C14.2045 4.88343 15.4687 5.54675 16.3094 6.74177C14.8045 7.6536 14.0601 8.93072 14.0749 10.5691C14.0885 11.8452 14.5514 12.9071 15.4613 13.7503C15.8736 14.1417 16.3341 14.4441 16.8464 14.6589C16.7353 14.9812 16.618 15.2898 16.4933 15.5861ZM12.6342 0.400114C12.6342 1.40034 12.2688 2.33425 11.5404 3.19867C10.6614 4.22629 9.59823 4.8201 8.4453 4.7264C8.43061 4.60641 8.42209 4.48011 8.42209 4.3474C8.42209 3.38718 8.8401 2.35956 9.58243 1.51934C9.95303 1.09392 10.4244 0.74019 10.996 0.458013C11.5663 0.180046 12.1058 0.0263242 12.6132 0C12.628 0.133715 12.6342 0.267438 12.6342 0.400101V0.400114Z" />
      </symbol>
      <symbol id="DevIcon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1 21.4207C20.1 24.5311 11.7267 22.0686 11.7267 22.0686C11.7267 22.0686 3.35336 24.5311 3.35336 21.4207C3.35336 18.3102 7.10222 15.7886 11.7267 15.7886C16.3511 15.7886 20.1 18.3102 20.1 21.4207Z" fill="#8A8A8D"/>
        <ellipse cx="11.7267" cy="9.66186" rx="3.98761" ry="4.29617" fill="#8A8A8D"/>
        <rect x="1.38885" y="1.67276" width="20.7082" height="20.7082" rx="4" stroke="#8A8A8D" strokeWidth="2"/>
      </symbol>
      <symbol id="backIcon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.94296 1.9359C10.3376 1.54951 10.3443 0.916381 9.95787 0.521761C9.57148 0.127142 8.93835 0.120467 8.54373 0.506853L9.94296 1.9359ZM1.8288 8.48122L1.12918 7.7667C0.938644 7.95326 0.830512 8.2082 0.828817 8.47486C0.827121 8.74152 0.932004 8.99782 1.12016 9.18679L1.8288 8.48122ZM8.5347 16.6336C8.92438 17.025 9.55754 17.0263 9.94891 16.6367C10.3403 16.247 10.3417 15.6138 9.95198 15.2225L8.5347 16.6336ZM8.54373 0.506853L1.12918 7.7667L2.52841 9.19574L9.94296 1.9359L8.54373 0.506853ZM1.12016 9.18679L8.5347 16.6336L9.95198 15.2225L2.53744 7.77565L1.12016 9.18679Z" fill="#3a82f7"/>
      </symbol>
      <symbol id="pm_arrowLeft" viewBox="0 0 31 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M31 16H3m0 0L17 2M3 16l14 14" strokeWidth="4"/>
      </symbol>
      <symbol id="pm_more" viewBox="0 0 7 31" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3.5" cy="3.5" r="3.5"/>
        <circle cx="3.5" cy="15.5" r="3.5"/>
        <circle cx="3.5" cy="27.5" r="3.5"/>
      </symbol>
      <symbol id="pm_search" viewBox="0 0 34 35" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13 4C8.02944 4 4 8.02944 4 13C4 17.9706 8.02944 22 13 22C17.9706 22 22 17.9706 22 13C22 8.02944 17.9706 4 13 4ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 16.3573 24.7274 19.4173 22.6382 21.724L24.2071 23.2929L24.9142 22.5858L33.9142 31.5858L33.2071 32.2929L31.7929 33.7071L31.0858 34.4142L22.0858 25.4142L22.7929 24.7071L21.1856 23.0998C18.9508 24.9133 16.1023 26 13 26C5.8203 26 0 20.1797 0 13Z"/>
      </symbol>
      <symbol id="pm_download" viewBox="0 0 40 39" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 22L18.5858 23.4142C19.3668 24.1953 20.6332 24.1953 21.4142 23.4142L20 22ZM0 5V34H4V5H0ZM5 39H35V35H5V39ZM40 34V5H36V34H40ZM35 0H5V4H35V0ZM18 8V22H22V8H18ZM21.4142 20.5858L13.9142 13.0858L11.0858 15.9142L18.5858 23.4142L21.4142 20.5858ZM21.4142 23.4142L28.9142 15.9142L26.0858 13.0858L18.5858 20.5858L21.4142 23.4142ZM10 31H30V27H10V31ZM40 5C40 2.23858 37.7614 0 35 0V4C35.5523 4 36 4.44772 36 5H40ZM35 39C37.7614 39 40 36.7614 40 34H36C36 34.5523 35.5523 35 35 35V39ZM0 34C0 36.7614 2.23858 39 5 39V35C4.44772 35 4 34.5523 4 34H0ZM4 5C4 4.44771 4.44772 4 5 4V0C2.23857 0 0 2.23858 0 5H4Z"/>
      </symbol>
      <symbol id="pm_ageRate" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1895 18.9873H19.7793C20.5814 18.9801 21.2152 18.7725 21.6807 18.3643C22.1533 17.9561 22.3896 17.3652 22.3896 16.5918C22.3896 15.847 22.1927 15.2705 21.7988 14.8623C21.4121 14.4469 20.8177 14.2393 20.0156 14.2393C19.3138 14.2393 18.7373 14.4434 18.2861 14.8516C17.835 15.2526 17.6094 15.779 17.6094 16.4307H14.999C14.999 15.6286 15.2103 14.8981 15.6328 14.2393C16.0625 13.5804 16.6569 13.0684 17.416 12.7031C18.1823 12.3307 19.0381 12.1445 19.9834 12.1445C21.5446 12.1445 22.7692 12.5384 23.6572 13.3262C24.5524 14.1068 25 15.1953 25 16.5918C25 17.2936 24.7744 17.9561 24.3232 18.5791C23.8792 19.195 23.3027 19.6605 22.5938 19.9756C23.4531 20.2692 24.1048 20.7311 24.5488 21.3613C25 21.9915 25.2256 22.7435 25.2256 23.6172C25.2256 25.0208 24.7422 26.138 23.7754 26.9688C22.8158 27.7995 21.5518 28.2148 19.9834 28.2148C18.4795 28.2148 17.2477 27.8138 16.2881 27.0117C15.3285 26.2096 14.8486 25.1426 14.8486 23.8105H17.459C17.459 24.498 17.6882 25.0566 18.1465 25.4863C18.612 25.916 19.235 26.1309 20.0156 26.1309C20.8249 26.1309 21.4622 25.916 21.9277 25.4863C22.3932 25.0566 22.626 24.4336 22.626 23.6172C22.626 22.7936 22.3825 22.1598 21.8955 21.7158C21.4085 21.2718 20.6852 21.0498 19.7256 21.0498H18.1895V18.9873Z" fill="#5D9552"/>
        <circle cx="20" cy="20" r="18" stroke="#5D9552" strokeWidth="3"/>
      </symbol>
      <symbol id="pm_info" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 14V9M9.5 7V5" strokeWidth="2"/>
        <circle cx="9.5" cy="9.5" r="8.5" strokeWidth="2"/>
      </symbol>
      <symbol id="pm_arrowRight" viewBox="0 0 31 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16H28M28 16L14 2M28 16L14 30" strokeWidth="4"/>
      </symbol>
      <symbol id="pm_arrowDown" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 2L13 13L2 2" strokeWidth="4"/>
      </symbol>
      <symbol id="pm_starFull" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0L15.1388 7.67985L23.4127 8.2918L17.0786 13.6502L19.0534 21.7082L12 17.34L4.94658 21.7082L6.92136 13.6502L0.587322 8.2918L8.86123 7.67985L12 0Z" fill="#3A8562"/>
      </symbol>
      <symbol id="pm_starHalf" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0L15.1388 7.67985L23.4127 8.2918L17.0786 13.6502L19.0534 21.7082L12 17.34L4.94658 21.7082L6.92136 13.6502L0.587322 8.2918L8.86123 7.67985L12 0Z" fill="#E8EAED"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.0001 4.89354L12.0001 0L8.86131 7.67985L0.587402 8.2918L6.92144 13.6502L4.94666 21.7082L12.0001 17.34L14.0001 18.5786V4.89354Z" fill="#3A8562"/>
      </symbol>
      <symbol id="pm_starEmpty" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0L15.1388 7.67985L23.4127 8.2918L17.0786 13.6502L19.0534 21.7082L12 17.34L4.94658 21.7082L6.92136 13.6502L0.587322 8.2918L8.86123 7.67985L12 0Z" fill="#E8EAED"/>
      </symbol>
      <symbol id="pm_web" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM13.5 26.85C7.65 26.1 3 21 3 15C3 14.1 3.15 13.2 3.3 12.3L10.5 19.5V21C10.5 22.65 11.85 24 13.5 24V26.85ZM23.85 23.1C23.4 21.9 22.35 21 21 21H19.5V16.5C19.5 15.6 18.9 15 18 15H9V12H12C12.9 12 13.5 11.4 13.5 10.5V7.5H16.5C18.15 7.5 19.5 6.15 19.5 4.5V3.9C23.85 5.7 27 10.05 27 15C27 18.15 25.8 21 23.85 23.1Z"/>
      </symbol>
      <symbol id="pm_letter" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.543 11.1581L16.3422 12.4275L15.543 11.1581ZM3.5 3H27V0H3.5V3ZM26.5 20.5H4V23.5H26.5V20.5ZM3.5 20V3H0.5V20H3.5ZM15.2838 9.89339L2.82087 1.74455L1.17913 4.25545L13.6421 12.4043L15.2838 9.89339ZM27 3V20H30V3H27ZM16.3422 12.4275L29.2992 4.26935L27.7008 1.73065L14.7438 9.88876L16.3422 12.4275ZM13.6421 12.4043C14.4602 12.9392 15.515 12.9483 16.3422 12.4275L14.7438 9.88876C14.9092 9.7846 15.1202 9.78641 15.2838 9.89339L13.6421 12.4043ZM4 20.5C3.72386 20.5 3.5 20.2761 3.5 20H0.5C0.5 21.933 2.067 23.5 4 23.5V20.5ZM26.5 23.5C28.433 23.5 30 21.933 30 20H27C27 20.2761 26.7761 20.5 26.5 20.5V23.5ZM27 3H30C30 1.34315 28.6569 0 27 0V3ZM3.5 0C1.84315 0 0.5 1.34315 0.5 3H3.5V0Z"/>
      </symbol>
      <symbol id="pm_mark" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5001 1C5.94828 1 1.43164 5.51846 1.43164 11.0725C1.43164 19.3476 10.4979 29.4275 10.884 29.7675C11.06 29.9225 11.28 30.0001 11.5001 30.0001C11.7202 30.0001 11.9402 29.9226 12.1163 29.7675C12.5022 29.4276 21.5685 19.3477 21.5685 11.0725C21.5685 5.51846 17.0519 1 11.5001 1ZM11.5001 27.7899C9.49564 25.8476 3.29619 17.3327 3.29619 11.0725C3.29619 6.54664 6.97646 2.86455 11.5001 2.86455C16.0237 2.86455 19.704 6.54664 19.704 11.0725C19.704 17.3327 13.5045 25.8475 11.5001 27.7899Z"/>
        <circle cx="11.5" cy="10.5" r="3.5"/>
      </symbol>
      <symbol id="pm_secure" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.53799 7.21127L12.0983 2.23097C12.3774 2.08557 12.7087 2.08005 12.9925 2.21606L23.4322 7.2194C23.7792 7.3857 24 7.73637 24 8.12118V13.2076C24 19.5029 20.3242 25.2702 14.6472 27.9909C13.3307 28.6218 11.7797 28.6031 10.485 27.9286C5.27054 25.2122 2 19.8208 2 13.9412V8.09814C2 7.72535 2.20737 7.3835 2.53799 7.21127Z" strokeWidth="3"/>
      </symbol>
      <symbol id="pm_moneyback" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#606367"/>
        <path d="M16.5 27.5H23C26.0376 27.5 28.5 25.0376 28.5 22V22C28.5 18.9624 26.0376 16.5 23 16.5H11M11 16.5L16.5 11M11 16.5L16.5 22" stroke="white" strokeWidth="3"/>
      </symbol>
      <symbol id="pm_claim" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 32.5V2H16V6H27V22H17V17.5H2.5" strokeWidth="4"/>
      </symbol>
      <symbol id="pm_share" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29" cy="6" r="4" strokeWidth="4"/>
        <circle cx="29" cy="33" r="4" strokeWidth="4"/>
        <circle cx="6" cy="19" r="4" strokeWidth="4"/>
        <path d="M25.5 7.5L9 17.5M9 20.5L25.5 30.5" strokeWidth="4"/>
      </symbol>
    </svg>
  </div>
)
