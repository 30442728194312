import React, { useEffect, useState } from "react"
import logEvent from "../../utils/logEvent"
import createAndClickLink from "../../utils/createLinkAndClick"
import SVGSprite from "../SVGSprite/SVGSprite"
import st from "./PlayMarket.module.css"
import appbonusLogo from "../../assets/images/playmarket/appbonus_logo.png"
import screenshot1 from "../../assets/images/playmarket/screenshot_1.png"
import screenshot2 from "../../assets/images/playmarket/screenshot_2.png"
import screenshot3 from "../../assets/images/playmarket/screenshot_3.png"
import screenshot4 from "../../assets/images/playmarket/screenshot_4.png"
import user1 from "../../assets/images/playmarket/user_1.png"
import user2 from "../../assets/images/playmarket/user_2.png"
import user3 from "../../assets/images/playmarket/user_3.png"

import tutorial_xiaomi_1 from "../../assets/images/playmarket/tutorial/xiaomi/1.jpg"
import tutorial_xiaomi_2_1 from "../../assets/images/playmarket/tutorial/xiaomi/2-1.jpg"
import tutorial_xiaomi_2_2 from "../../assets/images/playmarket/tutorial/xiaomi/2-2.jpg"
import tutorial_xiaomi_3_1 from "../../assets/images/playmarket/tutorial/xiaomi/3-1.jpg"
import tutorial_xiaomi_3_2 from "../../assets/images/playmarket/tutorial/xiaomi/3-2.jpg"
import tutorial_xiaomi_3_3 from "../../assets/images/playmarket/tutorial/xiaomi/3-3.jpg"

import tutorial_huawei_1 from "../../assets/images/playmarket/tutorial/huawei/1.jpg"
import tutorial_huawei_2_1 from "../../assets/images/playmarket/tutorial/huawei/2-1.png"
import tutorial_huawei_2_2 from "../../assets/images/playmarket/tutorial/huawei/2-2.png"
import tutorial_huawei_3_1 from "../../assets/images/playmarket/tutorial/huawei/3-1.png"
import tutorial_huawei_3_2 from "../../assets/images/playmarket/tutorial/huawei/3-2.png"

import tutorial_samsung_1 from "../../assets/images/playmarket/tutorial/samsung/1.jpg"
import tutorial_samsung_2 from "../../assets/images/playmarket/tutorial/samsung/2.jpg"
import tutorial_samsung_3_1 from "../../assets/images/playmarket/tutorial/samsung/3-1.jpg"
import tutorial_samsung_3_2 from "../../assets/images/playmarket/tutorial/samsung/3-2.jpg"

const screenshosts = [screenshot1, screenshot2, screenshot3, screenshot4]

const ratingStarsTpl = () => {
  const tpl = []
  for (let i = 0; i < 4; i++) {
    tpl[i] = (
      <svg className={st.rating__starItem} key={i}>
        <use xlinkHref="#pm_starFull" />
      </svg>
    )
  }
  tpl.push(
    <svg className={st.rating__starItem} key={tpl.length + 1}>
      <use xlinkHref="#pm_starHalf" />
    </svg>
  )

  return tpl
}

const reviewsStarsTpl = stars => {
  const tpl = []
  const maxRating = 5
  const emptyStars = maxRating - stars

  for (let i = 0; i < stars; i++) {
    tpl.push(
      <svg className={st.reviews__starItem} key={i}>
        <use xlinkHref="#pm_starFull" />
      </svg>
    )
  }

  for (let i = 0; i < emptyStars; i++) {
    tpl.push(
      <svg className={st.reviews__starItem} key={tpl.length + 1}>
        <use xlinkHref="#pm_starEmpty" />
      </svg>
    )
  }

  return tpl
}

const chart = [
  { id: 5, val: 80 },
  { id: 4, val: 0 },
  { id: 3, val: 15 },
  { id: 2, val: 0 },
  { id: 1, val: 30 },
]

const reviews = [
  {
    avatar: user1,
    name: "Ruslan",
    stars: 5,
    content:
      "Отличный вариант мобильного заработка! За легкие задания получаешь реальные деньги. Подходит тем, кто не знает чем заняться в свободное время.",
    date: "18.01.2021",
  },
  {
    avatar: user2,
    name: "Николай",
    stars: 4,
    content:
      "Хорошее приложение! Деньги вывел быстро на телефон. Никакой мороки. Хотелось бы зарабатывать больше. ",
    date: "12.02.2021",
  },
  {
    avatar: user3,
    name: "Olga",
    stars: 5,
    content:
      "Спасибо большое за приложение! Заработала 300р за два дня, не тратя много времени. Рада, что вас нашла!",
    date: "18.01.2021",
  },
]

const PlayMarket = ({ data }) => {
  const [expanded, setExpanded] = useState({
    contact: false,
    tutorial_xiaomi: false,
    tutorial_honor: false,
    tutorial_samsung: false,
  })

  const [curDrawer, setCurDrawer] = useState({
    descriptionDrawer: false,
    tutroialDrawer: false,
  })

  const [dropdown, setDropdown] = useState(false)

  const [urlCopied, setUrlCopied] = useState(false)

  const openDrawer = drawer => {
    setCurDrawer({
      ...curDrawer,
      [drawer]: true,
    })
  }

  const closeDrawer = () => {
    setCurDrawer({
      descriptionDrawer: false,
      tutroialDrawer: false,
    })
  }

  const toggleDropdown = () => {
    setDropdown(!dropdown)
  }

  const expandHandler = element => {
    const tmp = {}
    Object.keys(expanded).forEach(key => (tmp[key] = false))
    setExpanded({
      ...tmp, // Closing all other expanded elements
      [element]: expanded[element] ? false : true,
    })
  }

  const downloadBtnLanding = () => {
    createAndClickLink(data.appURL)
    logEvent("click", { url: data.pageURL, button: "download-app-landing" })
    openDrawer("tutroialDrawer")
  }

  const downloadBtnTutorial = () => {
    createAndClickLink(data.appURL)
    logEvent("click", { url: data.pageURL, button: "download-app-tutorial" })
  }

  const shareLink = () => {
    const { title } = data
    const pageURL = window.location.href

    if (navigator.share) {
      navigator.share({
        title,
        url: pageURL,
      })
    } else {
      navigator.clipboard.writeText(pageURL).then(() => {
        setDropdown(!dropdown)
        setUrlCopied(true)
        setTimeout(() => {
          setUrlCopied(false)
        }, 4000)
      })
    }
  }

  useEffect(() => {
    const body = document.body

    const lockBody = () => {
      body.style.top = `-${window.scrollY}px`
      body.style.left = "0"
      body.style.right = "0"
      body.style.position = "fixed"
    }

    const unlockBody = () => {
      const scrollY = body.style.top
      body.style.position = ""
      body.style.top = ""
      body.style.left = ""
      body.style.right = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }

    Object.keys(curDrawer).every(key => curDrawer[key] === false)
      ? unlockBody()
      : lockBody()
  }, [curDrawer])

  return (
    <>
      <SVGSprite />
      <div className={st.playmarket}>
        <div className="container">
          <div className={st.navbar}>
            <div>
              <svg className={st.navbar__back}>
                <use xlinkHref="#pm_arrowLeft" />
              </svg>
            </div>
            <div className={st.navbar__right}>
              <svg className={st.navbar__search}>
                <use xlinkHref="#pm_search" />
              </svg>
              <button className={st.navbar__more} onClick={toggleDropdown}>
                <svg className={st.navbar__moreIcon}>
                  <use xlinkHref="#pm_more" />
                </svg>
              </button>
            </div>
            <div className={`${st.dropdown} ${dropdown && st.dropdownVisible}`}>
              <div
                className={st.dropdown__backdrop}
                onClick={toggleDropdown}
              ></div>
              <div className={st.dropdown__container}>
                <button className={st.dropdown__item} onClick={shareLink}>
                  <svg className={st.dropdown__shareIcon}>
                    <use xlinkHref="#pm_share" />
                  </svg>
                  Поделиться
                </button>
                <a href="mailto:mail@appbonus.ru" className={st.dropdown__item}>
                  <svg className={st.dropdown__claimIcon}>
                    <use xlinkHref="#pm_claim" />
                  </svg>
                  Пожаловаться
                </a>
              </div>
            </div>
          </div>

          <div className={st.header}>
            <div className={st.header__logo}>
              <img
                src={appbonusLogo}
                alt="App logo"
                className={st.header__icon}
              />
            </div>
            <div className={st.header__content}>
              <h1 className={st.header__title}>
                Appbonus - мобильный заработок
              </h1>
              <p className={st.header__subtitle}>Appbonus LTD</p>
            </div>
          </div>
        </div>

        <div className={st.stat}>
          <div className={st.stat__col}>
            <div className={st.stat__label}>4,8&#9733;</div>
            <div className={st.stat__val}>33 отзыва</div>
          </div>
          <div className={st.stat__col}>
            <div className={st.stat__label}>
              <svg className={st.stat__download}>
                <use xlinkHref="#pm_download" />
              </svg>
            </div>
            <div className={st.stat__val}>7,2 МБ</div>
          </div>
          <div className={st.stat__col}>
            <div className={st.stat__label}>
              <svg className={st.stat__ageRate}>
                <use xlinkHref="#pm_ageRate" />
              </svg>
            </div>
            <div className={st.stat__val}>
              3+&nbsp;
              <svg className={st.stat__info}>
                <use xlinkHref="#pm_info" />
              </svg>
            </div>
          </div>
          <div className={st.stat__col}>
            <div className={st.stat__label}>10 тыс.+</div>
            <div className={st.stat__val}>Скачивания</div>
          </div>
        </div>

        <div className="container">
          <button
            className={`${st.button} ${st.buttonDownload}`}
            type="button"
            onClick={downloadBtnLanding}
          >
            Установить
          </button>
        </div>

        <div className={st.screenshots}>
          {screenshosts.map((item, index) => {
            return (
              <div className={st.screenshots__block} key={index}>
                <img
                  src={item}
                  alt={`App screenshot ${index + 1}`}
                  className={st.screenshots__item}
                />
              </div>
            )
          })}
        </div>

        <div className="container">
          <div className={st.description}>
            <div
              className={st.description__header}
              onClick={() => openDrawer("descriptionDrawer")}
            >
              <h2 className={st.description__title}>Описание</h2>
              <svg className={st.description__arrow}>
                <use xlinkHref="#pm_arrowRight" />
              </svg>
            </div>
            <p className={st.description__short}>
              Appbonus — мобильный заработок в твоем смартфоне. Ты можешь
              зарабатывать реальные деньги, выполняя простые и легкие задания.
            </p>
          </div>

          <div className={st.rating}>
            <div className={st.rating__header}>
              <h2 className={st.rating__title}>Оценки и отзывы</h2>
              <svg className={st.rating__info}>
                <use xlinkHref="#pm_info" />
              </svg>
            </div>
            <div className={st.rating__content}>
              <div className={st.rating__stat}>
                <div className={st.rating__rate}>4,8</div>
                <div className={st.rating__stars}>{ratingStarsTpl()}</div>
                <div className={st.rating__reviews}>33</div>
              </div>
              <div className={st.rating__graph}>
                {chart.map((item, index) => {
                  return (
                    <div className={st.rating__graphItem} key={index}>
                      <div className={st.rating__graphNum}>{item.id}</div>
                      <div className={st.rating__graphBar}>
                        <div
                          className={st.rating__graphBarVal}
                          style={{ width: `${item.val}%` }}
                        >
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className={st.reviews}>
            {reviews.map((item, index) => {
              return (
                <div className={st.reviews__item} key={index}>
                  <div className={st.reviews__header}>
                    <div className={st.reviews__headerLeft}>
                      <img
                        src={item.avatar}
                        alt="User"
                        className={st.reviews__avatar}
                      />
                      <span className={st.reviews__name}>{item.name}</span>
                    </div>
                    <div className={st.reviews__headerRight}>
                      <svg className={st.reviews__more}>
                        <use xlinkHref="#pm_more" />
                      </svg>
                    </div>
                  </div>
                  <div className={st.reviews__info}>
                    <div className={st.reviews__stars}>
                      {reviewsStarsTpl(item.stars)}
                    </div>
                    <span>{item.date}</span>
                  </div>
                  <p className={st.reviews__content}>{item.content}</p>
                  <div className={st.reviews__footer}>
                    <span>Был ли этот отзыв полезен?</span>
                    <div className={st.reviews__quiz}>
                      <button className={st.reviews__quizBtn} type="button">
                        Да
                      </button>
                      <button className={st.reviews__quizBtn} type="button">
                        Нет
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className={st.contact}>
            <div
              className={st.contact__header}
              onClick={() => expandHandler("contact")}
            >
              <h2 className={st.contact__title}>Связь с разработчиком</h2>
              <svg
                className={`${st.contact__arrow} ${expanded.contact &&
                  st.contact__arrowExpanded}`}
              >
                <use xlinkHref="#pm_arrowDown" />
              </svg>
            </div>
            <div
              className={`${st.contact__body} ${expanded.contact &&
                st.contact__bodyExpanded}`}
            >
              <div className={st.contact__item}>
                <div className={st.contact__icon}>
                  <svg className={st.contact__webIcon}>
                    <use xlinkHref="#pm_web" />
                  </svg>
                </div>
                <div className={st.contact__text}>
                  <a
                    className={st.contact__label}
                    href="https://appbonus.ru"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Сайт
                  </a>
                </div>
              </div>
              <div className={st.contact__item}>
                <div className={st.contact__icon}>
                  <svg className={st.contact__letterIcon}>
                    <use xlinkHref="#pm_letter" />
                  </svg>
                </div>
                <div className={st.contact__text}>
                  <div className={st.contact__label}>Электронная почта</div>
                  <a
                    href="mailto:mail@appbonus.ru"
                    className={st.contact__value}
                  >
                    mail@appbonus.ru
                  </a>
                </div>
              </div>
              <div className={st.contact__item}>
                <div className={st.contact__icon}>
                  <svg className={st.contact__markIcon}>
                    <use xlinkHref="#pm_mark" />
                  </svg>
                </div>
                <div className={st.contact__text}>
                  <div className={st.contact__label}>Адрес</div>
                  <div className={st.contact__value}>
                    55 St Mary’s St, Edinburgh E6H 2SX
                  </div>
                </div>
              </div>
              <div className={st.contact__item}>
                <div className={st.contact__icon}>
                  <svg className={st.contact__secureIcon}>
                    <use xlinkHref="#pm_secure" />
                  </svg>
                </div>
                <div className={st.contact__text}>
                  <a
                    className={st.contact__label}
                    href="https://www.appbonus.ru/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Конфиденциальность
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={st.moneyback}>
            <div className={st.moneyback__row}>
              <svg className={st.moneyback__icon}>
                <use xlinkHref="#pm_moneyback" />
              </svg>
              <span>Правила возврата платежей в Google Play</span>
            </div>
            <p>Все цены указаны с учетом НДС.</p>
          </div>
        </div>
      </div>

      <div
        className={`${st.drawer} ${curDrawer.tutroialDrawer &&
          st.drawerVisible}`}
      >
        <div className={st.tutorial}>
          <div className="container">
            <span className={st.tutorial__close} onClick={closeDrawer}>
              &times;
            </span>
            <div className={st.header}>
              <div className={st.header__logo}>
                <img
                  src={appbonusLogo}
                  alt="App logo"
                  className={st.header__icon}
                />
              </div>
              <div className={st.header__content}>
                <h1 className={st.header__title}>
                  Appbonus - мобильный заработок
                </h1>
                <p className={st.header__subtitle}>Appbonus LTD</p>
              </div>
            </div>
            <p className={st.tutorial__description}>
              Если загрузка началась, по её завершению нажмите на файл и
              выберите "Установить"
            </p>
            <p className={st.tutorial__description}>
              Если загрузка НЕ началась воспользуйтесь нашей инструкцией ниже
            </p>
            <div
              className={`${st.tutorial__item} ${expanded.tutorial_xiaomi &&
                st.tutorial__itemExpanded}`}
            >
              <div
                className={st.tutorial__itemHeader}
                onClick={() => expandHandler("tutorial_xiaomi")}
              >
                <h2 className={st.tutorial__itemTitle}>Xiaomi/Redmi</h2>
                <svg className={st.tutorial__itemArrow}>
                  <use xlinkHref="#pm_arrowDown" />
                </svg>
              </div>
              <div className={st.tutorial__itemBody}>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>1.</div>
                    <div className={st.tutorial__blockTitle}>
                      Опустите шторку и перейдите в настройки, нажав на иконку
                      "Настройки"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_1}
                    alt="Tutorial Xiaomi/Redmi 1"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>2.</div>
                    <div className={st.tutorial__blockTitle}>
                      Перейдите в "Защита конфиденциальности" -> "Специальные
                      разрешения"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_2_1}
                    alt="Tutorial Xiaomi/Redmi 2-1"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_2_2}
                    alt="Tutorial Xiaomi/Redmi 2-2"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>3.</div>
                    <div className={st.tutorial__blockTitle}>
                      Перейдите во "Внешние источники", выберите ваш браузер и
                      перетащите ползунок в состояние "ВКЛ"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_3_1}
                    alt="Tutorial Xiaomi/Redmi 3-1"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_3_2}
                    alt="Tutorial Xiaomi/Redmi 3-2"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_xiaomi_3_3}
                    alt="Tutorial Xiaomi/Redmi 3-3"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>4.</div>
                    <div className={st.tutorial__blockTitle}>
                      Нажмите ниже на кнопку “Загрузить АРК”
                    </div>
                  </div>
                  <button
                    className={`${st.button} ${st.tutorial__blockButton}`}
                    type="button"
                    onClick={downloadBtnTutorial}
                  >
                    Загрузить АРК
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`${st.tutorial__item} ${expanded.tutorial_honor &&
                st.tutorial__itemExpanded}`}
            >
              <div
                className={st.tutorial__itemHeader}
                onClick={() => expandHandler("tutorial_honor")}
              >
                <h2 className={st.tutorial__itemTitle}>Huawei/Honor</h2>
                <svg className={st.tutorial__itemArrow}>
                  <use xlinkHref="#pm_arrowDown" />
                </svg>
              </div>
              <div className={st.tutorial__itemBody}>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>1.</div>
                    <div className={st.tutorial__blockTitle}>
                      Опустите шторку и перейдите в настройки, нажав на иконку
                      "Настройки"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_huawei_1}
                    alt="Tutorial Huawei/Honor 1"
                  />
                </div>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>2.</div>
                    <div className={st.tutorial__blockTitle}>
                      Из настроек перейдите в "Приложения и уведомления", а
                      затем в "Специальный доступ"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_huawei_2_1}
                    alt="Tutorial Huawei/Honor 2-1"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_huawei_2_2}
                    alt="Tutorial Huawei/Honor 2-2"
                  />
                </div>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>3.</div>
                    <div className={st.tutorial__blockTitle}>
                      Перейдите в "Установка неизвестных приложений" и
                      перетащите ползунок в состояние "ВКЛ"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_huawei_3_1}
                    alt="Tutorial Huawei/Honor 3-1"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_huawei_3_2}
                    alt="Tutorial Huawei/Honor 3-2"
                  />
                </div>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>4.</div>
                    <div className={st.tutorial__blockTitle}>
                      Нажмите ниже на кнопку “Загрузить АРК”
                    </div>
                  </div>
                  <button
                    className={`${st.button} ${st.tutorial__blockButton}`}
                    type="button"
                    onClick={downloadBtnTutorial}
                  >
                    Загрузить АРК
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`${st.tutorial__item} ${expanded.tutorial_samsung &&
                st.tutorial__itemExpanded}`}
            >
              <div
                className={st.tutorial__itemHeader}
                onClick={() => expandHandler("tutorial_samsung")}
              >
                <h2 className={st.tutorial__itemTitle}>Samsung</h2>
                <svg className={st.tutorial__itemArrow}>
                  <use xlinkHref="#pm_arrowDown" />
                </svg>
              </div>
              <div className={st.tutorial__itemBody}>
                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>1.</div>
                    <div className={st.tutorial__blockTitle}>
                      Опустите шторку и перейдите в настройки, нажав на иконку
                      "Настройки"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_samsung_1}
                    alt="Tutorial Samsung 1"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>2.</div>
                    <div className={st.tutorial__blockTitle}>
                      Выберите "Экран блокировки и безопасности"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_samsung_2}
                    alt="Tutorial Samsung 2"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>3.</div>
                    <div className={st.tutorial__blockTitle}>
                      Перетащите ползунок в состояние "ВКЛ" и нажмите "ОК"
                    </div>
                  </div>
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_samsung_3_1}
                    alt="Tutorial Samsung 3-1"
                  />
                  <img
                    className={st.tutorial__blockImage}
                    src={tutorial_samsung_3_2}
                    alt="Tutorial Samsung 3-2"
                  />
                </div>

                <div className={st.tutorial__block}>
                  <div className={st.tutorial__blockHeader}>
                    <div className={st.tutorial__blockNum}>4.</div>
                    <div className={st.tutorial__blockTitle}>
                      Нажмите ниже на кнопку “Загрузить АРК”
                    </div>
                  </div>
                  <button
                    className={`${st.button} ${st.tutorial__blockButton}`}
                    type="button"
                    onClick={downloadBtnTutorial}
                  >
                    Загрузить АРК
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${st.drawer} ${curDrawer.descriptionDrawer &&
          st.drawerVisible}`}
      >
        <div className={st.descriptionFull}>
          <div className={st.descriptionFull__header}>
            <svg className={st.descriptionFull__back} onClick={closeDrawer}>
              <use xlinkHref="#pm_arrowLeft" />
            </svg>
            <img
              src={appbonusLogo}
              alt="App logo"
              className={st.descriptionFull__icon}
            />
            <div className={st.descriptionFull__headerText}>
              <h3 className={st.descriptionFull__title}>
                Appbonus - мобильный заработок
              </h3>
              <p className={st.descriptionFull__subtitle}>Сведения</p>
            </div>
          </div>
          <div className={st.descriptionFull__body}>
            <p>
              Appbonus — мобильный заработок в твоем смартфоне. Ты можешь
              зарабатывать реальные деньги, выполняя простые и легкие задания.
            </p>
            <p>
              Подработка для школьников или студентов? Для пенсионеров или
              молодых мам? Без вложений? Да! Appbonus позволяет легально и
              честно зарабатывать без вложения средств. Это новый вид мобильного
              заработка, который дает тебе возможность получать деньги прямо на
              телефон.
            </p>
            <p>
              Скачивай свои любимые игры или мобильные приложения, играй!
              Зарабатывай на свои мелкие расходы — деньги в твоем телефоне будут
              всегда.
            </p>
            <p>
              Выводить средства можно не только на мобильный телефон, но и на
              электронные кошельки. Можно забыть про долгие переводы — выплаты
              приходят очень быстро. Подработка для школьников — теперь в
              Appbonus.
            </p>
            <p>
              Если твоего мобильного заработка от выполнения обычных ежедневных
              заданий недостаточно, пригласи в приложение друзей по специальному
              промокоду и помоги им заработать! За каждого друга ты все время
              будешь получать процент от его дохода.
            </p>
            <p>Наши преимущества:</p>
            <ul>
              <li>Заработок реальных денег</li>
              <li>Простые задания</li>
              <li>Требуется только смартфон</li>
              <li>
                Быстрые выплаты на счет оператора или электронные кошельки
              </li>
              <li>Реферальная программа</li>
              <li>Не требует первоначальных инвестиций</li>
            </ul>
            <p>
              Начать работать просто как никогда — установи приложение на
              телефон и начни получать деньги! Без вложений: выполняй легкие
              задания и зарабатывай деньги.
            </p>

            <p>Заработок денег без вложений — это Appbonus!</p>
          </div>
        </div>
      </div>

      <div className={`${st.alert} ${urlCopied && st.alertVisible}`}>
        Ссылка скопирована
      </div>
    </>
  )
}

export default PlayMarket
