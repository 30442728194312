import React from "react"
import { Helmet } from "react-helmet"
import PlayMarket from "../components/PlayMarket/PlayMarket"

const data = {
  pageURL: "/2324/",
  appURL: "https://www.appbonus.ru/mobile_app/download/android",
  title: "Appbonus - мобильный заработок",
  gtm: "GTM-P9C9L22",
  lang: "ru",
}

const PlayMarketPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: data.lang,
        }}
        script={[
          {
            type: "text/javascript",
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${data.gtm}');`,
          },
        ]}
        title={data.title}
      />
      <PlayMarket data={data} />
    </>
  )
}

export default PlayMarketPage
